import { linePlanCalendarData } from "../actionType/actionType";

const initialState = {
  linePlanData: [],
};

const getLinePlanForCalendarFunc = (
  state = { ...initialState },
  { type, payload }
) => {
  switch (type) {
    case linePlanCalendarData.getLinePlanCalendarData:
      console.log("calpay", payload);
      return {
        ...state,
        linePlanData: payload,
      };
    default: {
      return state;
    }
  }
};

export default getLinePlanForCalendarFunc;
