import { history } from "helpers";
import { getToken } from "helpers/utis";
import PropTypes from "prop-types";
import { useEffect } from "react";
import "./authlayout.scss";

export function AuthLayout(props) {
  const { children } = props;

  // const websiteGuard = useCallback(() => {
  //   if (getToken()) history.push("/dashboard");
  // }, []);

  // useEffect(() => {
  //   websiteGuard();
  // });

  return <div className="auth-content-wrapper">{children}</div>;
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
