import moment from "moment";

import { Toast } from "service/toast";
import axios from "axios";
import { history } from "helpers";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export const dateFormatter = (date, format = "DD/MM/YYYY") => {
  if (!date) return "";

  const finalDate = moment(date).format(format);
  return finalDate === "Invalid date" ? "" : finalDate;
};

/**
 * Extract the right error message from react-hook-form error object
 *
 * @param {object} errors Errors object from react-hook-form
 * @param {object} fieldArrayError  { index, fieldName: "date", arrayName: "callLogs", }
 * @param {string} name
 */

export const getFormErrorMessage = (errors, fieldArrayError, name) => {
  if (!name || !errors) return null;

  if (!fieldArrayError && name) {
    return errors[name];
  }

  return (
    !!errors[fieldArrayError.arrayName] &&
    errors[fieldArrayError.arrayName][fieldArrayError.index] &&
    typeof errors[fieldArrayError.arrayName][fieldArrayError.index][
      fieldArrayError.fieldName
    ] !== "undefined" &&
    errors[fieldArrayError.arrayName][fieldArrayError.index][
      fieldArrayError.fieldName
    ]
  );
};

export const urlCombiner = (url) => {
  const protocol = ["h", "t", "t", "p", "s"].join("");

  return `${protocol}://${url}`;
};

export const defaultValidator = (val, defaultValue) => {
  return val || defaultValue;
};

export const ToastError = (err) => {
  return Toast({ type: "error", message: err.Error || err.message || "Error" });
};

export const useParamQuery = (val) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get(val);
  return paramValue;
};

// add Query
export const addQuery = (dataObject, apiObject) => {
  if (!dataObject) {
    return "";
  }

  

  const keys = [
    "page",
    "search",
    "size",
    "trimRequestIds",
    "costingSheetId",
    "fabricRequestId",
    "fabricDetailsId",
    "fabricPOId",
    "trimPOId",
    "trimDetailsId",
    "sortBy",
    "sortColumn",
    "fileName",
    "dateModified",
    "url_id",
    "status",
    "startDate",
    "endDate",
    "unitId",
    "lineId",
    "mainList",
    "filterStatus",
    "unOrderedList",
    "type",
    "detailsId",
    "reqId",
    "fabricRequestIds",
    "filterForGoods",
    "limit",
    "color",
    "currentDate",
  ];

  keys.forEach((key) => {
    if (dataObject.hasOwnProperty(key) && typeof dataObject[key] != "object") {
      if (apiObject.query.hasOwnProperty(key)) {
        apiObject.addQuery = { key, payload: dataObject[key] };
      }
    } else {
      dataObject[key] &&
        Object.keys(dataObject[key]).forEach((keyName) => {
          if (apiObject.query.hasOwnProperty(keyName)) {
            apiObject.addQuery = {
              key: keyName,
              payload: dataObject[key][keyName],
            };
          }
        });
    }
  });
};

// generate Query
export const generateQuery = (query) => {
  let url = "";
  if (query.hasOwnProperty("url_id")) {
    url = `/${query?.url_id}`;
  }

  return (
    url +
    Object.keys(query).reduce((accumulator, key, index) => {
      if (
        query[key] === "" ||
        query[key] == null ||
        key === "url_id" ||
        (query[key] !== null && query[key].toString().trim() === "")
      ) {
        return accumulator;
      } else {
        return accumulator + `${index !== 0 ? "&" : "?"}${key}=${query[key]}`;
      }
    }, "")
  );
};

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("token");
  }
};

export const ternaryCondition = (val1, val2 = "", defaultValue = "") => {
  return val1 ? val2 : defaultValue;
};

//get s.no
export const getSNo = (index, page = 1) => {
  return parseInt((page - 1) * 10) + index + 1;
};

export const getIpAddress = async () => {
  const data = await axios({ method: "get", url: "https://api.ipify.org" });
  let ip = data?.data;
  localStorage.setItem("ip", ip);
};

export const getIp = () => {
  return defaultValidator(localStorage.getItem("ip"), "");
};

export const goBack = () => {
  history.goBack();
};

export const getRoleId = () => {
  let user = JSON.parse(localStorage.getItem("userDetails"));
  return user.role;
};
export const getUserName = () => {
  let user = JSON.parse(localStorage.getItem("userDetails"));
  return user.username;
};
