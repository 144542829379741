export const menuItems = [
  {
    module: "Costing Sheet",
    icon: "costing-sheet",
    link: "/costing/costing-sheet",
    value: "Costing Sheet",
  },
  {
    module: "Order Sheet",
    icon: "order-sheet",
    link: "/orders/order-sheet",
    value: "Order Details Page",
  },
  {
    module: "Fabric Ordering",
    icon: "fabric",
    link: "/fabric-ordering",
    value: "Fabric Ordering",
  },
  {
    module: "TRIM Ordering",
    icon: "trim",
    link: "/orders/trim-ordering",
    value: "Trim Ordering",
  },
  {
    module: "Inward Goods",
    icon: "inward-goods",
    link: "/inward-goods",
    value: "Inward Goods",
  },
  {
    module: "Goods Issue",
    icon: "fabric",
    link: "/goods-issue",
    value: "Goods Issue",
  },
  {
    module: "Line Plan",
    icon: "line-plan",
    link: "/line-plan",
    value: "Line Plan",
  },
  {
    module: "Production Status",
    icon: "production-status",
    link: "/production-status",
    value: "Production Status",
  },

  {
    module: "Add user",
    icon: "settings",
    link: "/add-user",
    value: "Add User",
  },
];

export const costSheetHeaders = [
  { label: "Order Type" },
  { label: "Customer Order Reference", isSort: true, key: "orderReference" },
  { label: "Customer Name", isSort: true, key: "customerName" },
  { label: "Season", isSort: true, key: "season" },
  { label: "Style", isSort: true, key: "style" },
  { label: "Ex-Factory Date", isSort: true, key: "exFactoryDate" },
  { label: "Quantity", isSort: true, key: "totalQuantity" },
  { label: "Status" },
  { label: "Action" },
];

export const fabricListOrders = [
  { label: "S No" },
  { label: "Customer Order Reference", isSort: true, key: "orderReference" },
  { label: "Customer Name", isSort: true, key: "customerName" },
  { label: "Season", isSort: true, key: "season" },
  { label: "Fabric Code", isSort: true, key: "fabricCode" },
  { label: "Fabric Name", isSort: true, key: "fabricName" },
  { label: "Quantity", isSort: true, key: "totalQuantity" },
  { label: "Consumption", isSort: true, key: "consumption" },
  { label: "Raised On", isSort: true, key: "raisedOn" },
  { label: "Ordering Status" },
  { label: "Fabric Status" },
  { label: "Action" },
];

export const OrderFabricHeaders = [
  {
    label: "Customer Order Reference",
    isSort: true,
    key: "customerOrderReference",
  },
  { label: "Customer Name", isSort: true, key: "customerName" },
  { label: "Season", isSort: true, key: "season" },
  { label: "Style Name", isSort: true, key: "style" },
];

export const FabricOrderApprovals = [
  { label: "S No", key: "no" },
  { label: "PO Number", isSort: true, key: "poNumber" },
  { label: "Customer Order Reference" },
  { label: "Mill Name", isSort: true, key: "millName" },
  { label: "Mill Sort No", isSort: true, key: "millSortNo" },
  { label: "Delivery Date", isSort: true, key: "deliveryDate" },
  { label: "Action" },
];
export const trimOrdersHeaders = [
  { label: "" },
  { label: "Customer Order Reference", isSort: true, key: "orderReference" },
  { label: "Order Type", isSort: true, key: "orderType" },
  { label: "Customer Name", isSort: true, key: "customerName" },
  { label: "Season", isSort: true, key: "season" },
  { label: "Style", isSort: true, key: "style" },
  { label: "Quantity", isSort: true, key: "totalQuantity" },
];

export const trimOrdersApprovalHeaders = [
  { label: "S.No" },
  { label: "PO Number" },
  { label: "Customer Order Reference" },
  { label: "Supplier", isSort: true, key: "supplier" },
  { label: "SupplierSortNo", isSort: true, key: "supplierSortNo" },
  { label: "Delivery Date", isSort: true, key: "deliveryDate" },
  { label: "Action" },
  { label: "" },
];

export const selectOrdersHeaders = [
  { label: "Customer Order Reference" },
  { label: "Style Name", isSort: true, key: "styleName" },
  { label: "Season", isSort: true, key: "season" },
  { label: "Trim Code", isSort: true, key: "trimCode" },
  { label: "Trim Type", isSort: true, key: "trimType" },
  { label: "Trim Description", isSort: true, key: "trimDescription" },
  { label: "Requisition Date", isSort: true, key: "requisitionDate" },
  { label: "Ordering Status" },
  { label: "Trim Status" },
  // { label: "Status", isSort: true, key: "trimStatus" },
  { label: "Action" },
  { label: "" },
];

export const trimOrderSubHeader = [
  { label: "S.No" },
  { label: "Trim Code" },
  { label: "Trim Details" },
  { label: "Requirement" },
  { label: "Units" },
  { label: "Action" },
];

export const fabricOrderSubHeader = [
  { label: "S.No" },
  { label: "Fabric Name" },
  { label: "Price" },
  { label: "Duty" },
  { label: "Action" },
];

export const stocksHeaders = [
  { label: "Trim code", isSort: true, key: "trimCode" },
  { label: "Purchase Date" },
  { label: "Colour Available" },
  { label: "Unallocated stock available" },
  { label: "Warehouse" },
  { label: "Customer" },
  { label: "Allocate" },
];

export const orderQuantityHeaders = [
  { label: "Colour" },
  { label: "Quantity" },
  { label: "Tolerance Quantity" },
];

export const trimOrderslistInFormHeaders = [
  { label: "S.No" },
  { label: "Trim Code" },
  { label: "Trim Type", isSort: true, key: "trimType" },
  { label: "Requirement", isSort: true, key: "requirement" },
  { label: "Unit", isSort: true, key: "unit" },
  { label: "Raised On", isSort: true, key: "raisedOn" },
  { label: "" },
];

export const passwordHintData = [
  { name: "UpperCase", status: false },
  { name: "LowerCase", status: false },
  { name: "Number", status: false },
  { name: "Special", status: false },
];

export const waistData = [
  [
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
    { id: 4, value: "" },
    { id: 5, value: "" },
  ],
  [
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
    { id: 4, value: "" },
    { id: 5, value: "" },
  ],
];

export const inseamLengthData = [{ id: 1, value: "" }];

export const orderTypeOptions = [
  { label: "Confirmed Order", value: "Confirmed Order" },
  { label: "Projection Order", value: "Projection Order" },
  { label: "Sample Order", value: "Sample Order" },
];

export const trimListSteps = [
  { title: "Order Selection", subtitle: "STEP 1", value: 1 },
  { title: "Trim Requisitions", subtitle: "STEP 2", value: 2 },
  { title: "Trim Selection", subtitle: "STEP 3", value: 3 },
  { title: "Trim Order Details", subtitle: "STEP 4", value: 4 },
  // { title: "Select Requirements ", subtitle: "STEP 4", value: 4 },
];

export const orderingQuantityData = [
  {
    id: 1,
    colorValue: "Red",
    QuantityValue: 1000,
  },
  {
    id: 2,
    colorValue: "Blue",
    QuantityValue: 1000,
  },
  {
    id: 3,
    colorValue: "Green",
    QuantityValue: 750,
  },
  {
    id: 4,
    colorValue: "Black",
    QuantityValue: 500,
  },
];

export const trimPurchaseHeaders = [
  { label: "" },
  { label: "Trim Code", isSort: true, key: "trimcode" },
  { label: "Trim Type" },
  { label: "Requirement" },
  { label: "Actual Price Per Trim" },
];

export const TrimPurchaseSubTable = [
  { label: "S.NO" },
  { label: "Color" },
  { label: "Trim Description" },
  { label: "Tolerance Requirement" },
  { label: "Available Stocks" },
  { label: "Associate Stocks" },
  { label: "Final Requirement" },
  { label: "Price Per Quantity" },
  { label: "Total Price" },
  { label: "Currency"}
];

export const fabricOrderSteps = [
  { title: "Order Selection", subtitle: "STEP 1", value: 1 },
  { title: "Fabric Requisition", subtitle: "STEP 2", value: 2 },
  { title: "Fabric Selection ", subtitle: "STEP 3", value: 3 },
  { title: "Fabric Order Details", subtitle: "STEP 4", value: 4 },
];

export const colorQuantityTableHeaders = [
  {
    label: "Colour",
  },
  {
    label: "Quantity",
  },
  {
    label: "Tolerance Quantity",
  },
];

// Stock Quantity Table Headers
export const stockQuantities = [
  {
    label: "Trim Code",
  },
  {
    label: "Purchased Date",
  },
  {
    label: "Unallocated stock available",
  },
  {
    label: "Warehouse",
  },
  {
    label: "Customer",
  },
  {
    label: "Allocate",
  },
];

// InwardGoods
// FabricGRN Headers
export const fabricGrnHeaders = [
  { label: "" },
  { label: "Fabric Code" },
  { label: "PO Number" },
  { label: "Fabric Details" },
  { label: "Color" },

  // { label: "Requirement" },
  // { label: "Units" },
  // { label: "Received" },
  { label: "GRN Notes" },
];

// fabric grn subheaders
export const fabricGrnSubHeaders = [
  { label: "GRN No#" },
  { label: "GRN Qty" },
  { label: "GRN Date" },
  { label: "Status" },
  { label: "Date of Checking" },
  { label: "Total Qty" },
  { label: "Passed" },
  { label: "Failed" },
  { label: "Fabric Checking" },
];

// create fabricgrn
export const createFabricHeader = [
  { label: "" },
  { label: "PO Number" },
  { label: "PO Status" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
];

// create fabricgrn
export const createFabricSubHeader = [
  { label: "" },
  { label: "S.No" },
  { label: "Fabric Code" },
  { label: "Fabric Details" },
  { label: "Requirement" },
  // { label: "Previous GRN" },

  // { label: "Received" },
  // { label: "GRN Notes" },
  // { label: "" },
];

// create fabricgrn
export const createFabricSubListHeader = [
  { label: "S.No" },
  { label: "Color" },
  { label: "Tolerance Quantity" },
  { label: "Previous GRN" },
  { label: "Received" },
  { label: "GRN Notes" },
  { label: "" },
];

export const customSelectOptions = [
  { value: 1, label: "Approve" },
  { value: 3, label: "Revert" },
];

export const fabricGoodIssuesTableHeaders = [
  { label: "" },
  { label: "Customer Order Reference", isSort: true, key: "orderReference" },
  { label: "Total Fabrics Used", isSort: true, key: "totalFabricCount" },
  { label: "Style Number", isSort: true, key: "styleNum" },
  { label: "Order Status" },
  { label: "Fabrics Status" },
];
export const fabricGoodIssuesSubTableHeaders = [
  { label: "" },
  { label: "S.No" },
  { label: "Fabric Code" },
  { label: "Fabric Details" },
  { label: "Requirement" },
  { label: "Units" },
  // { label: "Actions" },
];

export const fabricAfterRequisitionSelected = [
  { label: "" },
  { label: "S.No" },
  { label: "Fabric Code" },
  { label: "Fabric Details" },
  { label: "Requirement" },
  { label: "Units" },
  // { label: "Actions" },
];

export const fabricAfterRequisitionSubList = [
  { label: "S.No" },
  { label: "Color" },
  { label: "Requirement" },
  { label: "Units" },
  { label: "Actions" },
];

export const issueSelectOptions = [
  { value: 1, label: "Issued" },
  { value: 4, label: "Not Issued" },
];

export const trimGoodsTableHeaders = [
  { label: "" },
  { label: "Customer Order Reference", isSort: true, key: "orderReference" },
  { label: "Total Trims Used", isSort: true, key: "totalTrimCount" },
  { label: "Style Number", isSort: true, key: "styleNum" },
  { label: "Order Status" },
  { label: "Trim Status" },
];

export const trimSubTableHeaders = [
  { label: "" },
  { label: "S.No" },
  { label: "Trim Code" },
  { label: "Trim Details" },
  { label: "Requirement" },
  { label: "Units" },
  // { label: "Trim Needed" },
];
export const trimGrnHeaders = [
  { label: "" },
  { label: "Trim Code" },
  { label: "PO Number" },
  { label: "Trim Details" },
  { label: "Color" },

  // { label: "Requirement" },
  // { label: "Units" },
  // { label: "Received" },
  { label: "GRN Notes" },
];

// fabric grn subheaders
export const trimGrnSubHeaders = [
  { label: "GRN No#" },
  { label: "GRN Qty" },
  { label: "GRN Date" },
  { label: "Status" },
  { label: "Date of Checking" },
  { label: "Total Qty" },
  { label: "Passed" },
  { label: "Failed" },
  { label: "Trim Checking" },
];

// create fabricgrn
export const createTrimHeader = [
  { label: "" },
  { label: "PO Number" },
  { label: "Order Status" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "" },
];

// create fabricgrn
export const createTrimSubHeader = [
  { label: "" },
  { label: "S.No" },
  { label: "Trim Code" },
  { label: "Trim Details" },
  { label: "Requirement" },
  // { label: "Previous Grn" },
  // { label: "Received" },
  // { label: "GRN Notes" },
  // { label: "" },
];

// fabric detail header
export const fabricDetailHeaders = [
  { label: "Previous Received" },
  { label: "GRN Notes" },
];
export const trimAfterRequisitionSelected = [
  { label: "" },
  { label: "S.No" },
  { label: "Trim Code" },
  { label: "Trim Details" },
  { label: "Requirement" },
  { label: "Units" },
];
export const commonFabricTrimToleranceHeaders = [
  {
    label: "Colour",
    isSort: true,
    key: "color",
  },
  {
    label: "Tolerance Quantity",
  },
];

//Fabric purchase order

// fabric grn subheaders
export const fabricPurchaseOrder = [
  { label: "" },
  { label: "Fabric Code", isSort: true, key: "fabricCode" },
  { label: "Fabric Type" },
  { label: "Requirement" },
  { label: "Actual Price Per Fabric" },
  // { label: "Available Stocks" },
];
export const fabricPurchaseSubTable = [
  { label: "S.NO" },
  { label: "Color" },
  { label: "Fabric Description" },
  { label: "Tolerance Requirement" },
  { label: "Available Stocks" },
  { label: "Associate Stocks" },
  { label: "Final Requirement" },
  { label: "Price Per Quantity" },
  { label: "Total Price" },
  {label:  "Currency"}
];

export const rolesHeaders = [
  {
    label: "S.NO",
  },
  {
    label: "Role",
    isSort: true,
    key: "roleName",
  },
  {
    label: "Permissions",
  },
  {
    label: "Actions",
  },
];

export const userListsHeaders = [
  {
    label: "S.NO",
  },
  {
    label: "Name",
    isSort: true,
    key: "username",
  },
  {
    label: "Phone Number",
    isSort: true,
    key: "mobile",
  },
  { label: "Email Id", isSort: true, key: "email" },
  { label: "Role" },
  { label: "Actions" },
];

export const viewFabricGoodsNeeded = [
  { label: "S.NO" },
  { label: "Fabric Needed" },
  { label: "Requested By" },
  { label: "Requested Date" },
  { label: "Fabric Issued" },
  { label: "Issued By" },
  { label: "Issued Date" },
  { label: "Status" },
];

export const viewTrimGoodsNeeded = [
  { label: "S.NO" },
  { label: "Trim Needed" },
  { label: "Requested By" },
  { label: "Requested Date" },
  { label: "Trim Issued" },
  { label: "Issued By" },
  { label: "Issued Date" },
  { label: "Status" },
];

export const viewFabricIssued = [
  { label: "S.NO" },
  { label: "Fabric Needed" },
  { label: "Requested By" },
  { label: "Requested Date" },
  { label: "Fabric Issued" },
  { label: "Issued By" },
  { label: "Issued Date" },
  { label: "Status" },
];

export const viewTrimIssued = [
  { label: "S.NO" },
  { label: "Trim Needed" },
  { label: "Requested By" },
  { label: "Requested Date" },
  { label: "Trim Issued" },
  { label: "Issued By" },
  { label: "Issued Date" },
  { label: "Status" },
];

export const linePlanHeaders = [
  { label: "" },
  { label: "Line 1" },
  { label: "Line 2" },
  { label: "Line 3" },
  { label: "Line 4" },
  { label: "Line 5" },
  { label: "Line 6" },
  { label: "Line 7" },
  { label: "Line 8" },
  { label: "Line 9" },
  { label: "Line 10" },
];

export const productionTable = [
  { label: "S.No" },
  { label: "Customer Order Reference" },
  { label: "Order Type" },
  { label: "Customer Name", isSort: true, key: "customerName" },
  { label: "Season", isSort: true, key: "season" },
  { label: "Style", isSort: true, key: "style" },
  { label: "Quantity", isSort: true, key: "totalQuantity" },
  { label: "Action" },
];

export const productionFabricData = [
  { label: "" },
  { label: "Fabric Code" },
  { label: "Fabric Details" },
  { label: "Requirements" },
  { label: "Units" },
  { label: "Goods Status" },
  { label: "Order Status" },
];

export const productionTrimData = [
  { label: "" },
  { label: "Trim Code" },
  { label: "Trim Details" },
  { label: "Requirements" },
  { label: "Units" },
  { label: "Goods Status" },
  { label: "Order Status" },
];

export const FabricPOSubTable = [
  { label: "S No" },
  { label: "PO Number" },
  { label: "Mill Name" },
  { label: "Mill Sort No" },
  { label: "Color" },
  { label: "Delivery Date" },
  { label: "Status" },
];

export const FabricGRNSubtable = [
  { label: "GRN NO#" },
  { label: "GRN Qty" },
  { label: "GRN Notes" },
  { label: "GRN Date" },
  { label: "Passed Quantity" },
  { label: "Failed Quantity" },
  { label: "Date of Checking" },
  { label: "Status" },
];

export const TrimPOSubTable = [
  { label: "PO Number" },
  { label: "Supplier Name" },
  { label: "Supplier Sort No" },
  { label: "Color" },
  { label: "Delivery Date" },
  { label: "Status" },
];

export const TrimGrnTable = [
  { label: "GRN NO#" },
  { label: "GRN Qty" },
  { label: "GRN Notes" },
  { label: "GRN Date" },
  { label: "Passed Quantity" },
  { label: "Failed Quantity" },
  { label: "Date of Checking" },
  { label: "Status" },
];
