import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { history } from "helpers";
import { ternaryCondition } from "helpers/utis";

export function ListItem({ icon, module, link }) {
  const [isActiveItem, setIsActiveItem] = useState(false);
  useEffect(() => {
    console.log(link, "linkss");
    const pathName = history.location.pathname;
    setIsActiveItem(pathName === link);
  }, [history.location.pathname]);

  const handleClick = (routeLink) => {
    return history.push(routeLink);
  };

  return (
    <li
      className={`item mb-1 ${ternaryCondition(
        isActiveItem,
        "active-item",
        ""
      )}`}
    >
      <button type="button" onClick={() => handleClick(link)}>
        {icon && <i className={`icon-${icon}`} />}
        <p className="item-label">{module}</p>
      </button>
    </li>
  );
}

ListItem.propTypes = {
  icon: PropTypes.string,
  module: PropTypes.string,
  link: PropTypes.string,
};

ListItem.defaultProps = { icon: "", title: "", link: "" };
