import PropTypes from "prop-types";

import AdminNavbar from "components/Navbar";

import "./mainlayout.scss";
import { Sidebar } from "components/common/Sidebar";
import { useCallback, useEffect, useState } from "react";
import { getIpAddress, getToken } from "helpers/utis";
import { logout } from "service/utilities";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export function MainLayout(props) {
  const { children } = props;

  const [isOpen, setOpen] = useState(true);

  const toggle = () => setOpen(!isOpen);

  const location = useLocation();

  // const websiteGuard = useCallback(() => {
  //   if (!getToken()) logout();
  // }, []);

  // useEffect(() => {
  //   websiteGuard();
  // });

  // useEffect(() => {
  //   if (!localStorage.getItem("ip")) {
  //     getIpAddress();
  //   }
  // }, []);

  return (
    <div className={`main-layout `}>
      {location.pathname !== "/notification" && (
        <Sidebar toggle={toggle} isOpen={isOpen} />
      )}
      <div className="main-layout-wrapper">
        <AdminNavbar isOpen={isOpen} />
        <div
          className={`${
            location.pathname !== "/notification"
              ? "main-content-wrapper"
              : "notification-content-wrapper"
          } ${isOpen ? "open-layout" : "closed-layout"}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
