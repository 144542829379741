import { urlCombiner } from "./helpers/utis";

const config = {
  development: {
    baseUrl: urlCombiner("serverBaseUrl"),
    api: {
      userService: urlCombiner("doodlebluelive.com:2279"),
      paymentService: urlCombiner("apiBaseUrl"),
      normalService: urlCombiner("apiBaseUrl"),
      cmsService: urlCombiner("apiBaseUrl"),
      bookingService: urlCombiner("apiBaseUrl"),
    },
  },
  staging: {
    baseUrl: urlCombiner("serverBaseUrl"),
    api: {
      userService: urlCombiner("backendmeenakshi.doodlebluelive.com"),
      paymentService: urlCombiner("apiBaseUrl"),
      normalService: urlCombiner("apiBaseUrl"),
      cmsService: urlCombiner("apiBaseUrl"),
      bookingService: urlCombiner("apiBaseUrl"),
    },
  },
  production: {
    baseUrl: urlCombiner("serverBaseUrl"),
    api: {
      userService: urlCombiner("api.milgroup.in"),
      paymentService: urlCombiner("apiBaseUrl"),
      normalService: urlCombiner("apiBaseUrl"),
      cmsService: urlCombiner("apiBaseUrl"),
      bookingService: urlCombiner("apiBaseUrl"),
    },
  },
};

let configBasedOnEnv = {};

const env = process.env.NEXT_PUBLIC_ENV_VARIABLE;

configBasedOnEnv = config[env] ? config[env] : config.production;

export default configBasedOnEnv;
