import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Logo from "assets/images/svg/logo.svg";
import smallLogo from "assets/images/favicon.png";

import "./sidebar.scss";
import { menuItems } from "Data/staticDatas";
import { ListItem } from "./ListItem";
import { useEffect, useState } from "react";

/**
 * Sidebar: The common re-usable sidebar componentt
 * @return {JSX.Element} The JSX Code for the Sidebar component
 */

export function Sidebar({ isOpen, toggle }) {
  console.log(isOpen, "isOpen");
  const [userData, setUserData] = useState(menuItems);

  useEffect(() => {
    const userModules = localStorage.getItem("userDetails");
    const data = JSON.parse(userModules);
    const permissionModule = data.permission;
    const thirdArray = userData.filter((elem) =>
      permissionModule.some((ele) => ele.module === elem.value)
    );
    setUserData(thirdArray);
  }, []);

  return (
    <div
      className={`custom-sidebar ${isOpen ? "open-sidebar" : "closed-sidebar"}`}
      onClick={toggle}
    >
      <div className="menu-container">
        {isOpen ? (
          <Link to={userData[0].link} className="sidebar-logo">
            <img src={Logo} alt="Brand Logo" />
          </Link>
        ) : (
          <Link to={userData[0].link} className="sidebar-logo">
            <img src={smallLogo} alt="Brand Logo" width={60} height={60} />
          </Link>
        )}
      </div>
      <div className="menu-sidebar py-md-4 py-3 px-1">
        <ul className="items-container">
          {userData?.map((data) => (
            <>
              <ListItem {...data} key={data.module} />
            </>
          ))}
        </ul>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

Sidebar.defaultProps = {
  toggle: () => null,
  isOpen: true,
};
