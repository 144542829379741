import { notification } from "../actionType/actionType";

const initialState = {
  count: 0,
};

const notificationFunc = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case notification.getNotificationCount:
      return {
        ...state,
        count: payload,
      };
    default: {
      return state;
    }
  }
};

export default notificationFunc;
