import { AuthApi } from "service/apiVariables";

// login
export const login =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...AuthApi.loginApi, body })
        .then(({ user, message }) => {
          Toast({ type: "success", message: message });
          localStorage.setItem("userDetails", JSON.stringify(user.data));
          localStorage.setItem("token", user.token);
          resolve(user);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

export const forgotPasswordAct =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...AuthApi.forgotPasswordApi, body })
        .then(({ user, message }) => {
          Toast({ type: "success", message: message });
          resolve(user);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

export const resetPasswordAct =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...AuthApi.resetPasswordApi, body })
        .then(({ user, message }) => {
          Toast({ type: "success", message: message });
          resolve(user);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };
