const routers = [
  {
    path: "/",
    redirect: "/auth/login",
    auth: false,
  },
  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    name: "Auth",
    exact: false,
    redirect: "/auth/login",
    childrens: [
      {
        component: "Login",
        path: "/login",
        componentPath: "pages/Auth/login",
        name: "Login",
        auth: false,
        exact: true,
      },
      {
        component: "RegisterPage",
        path: "/register",
        componentPath: "pages/Auth/register",
        name: "RegisterPage",
        auth: false,
        exact: true,
      },
      {
        component: "ForgotPasswordPage",
        path: "/forgot-password",
        componentPath: "pages/Auth/forgotPassword",
        name: "ForgotPasswordPage",
        auth: false,
        exact: true,
      },
      {
        component: "ResetPasswordPage",
        path: "/reset-password",
        componentPath: "pages/Auth/resetPassword",
        name: "ResetPasswordPage",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/orders",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "NewOrderPage",
        path: "/new-order",
        componentPath: "pages/Orders/addNewOrder",
        name: "NewOrderPage",
        auth: false,
        exact: true,
      },
      {
        component: "NewOrderPage",
        path: "/edit-order",
        componentPath: "pages/Orders/addNewOrder",
        name: "NewOrderPage",
        auth: false,
        exact: true,
      },
      {
        component: "ViewOrderPage",
        path: "/view-order",
        componentPath: "pages/Orders/viewOrder",
        name: "ViewOrderPage",
        auth: false,
        exact: true,
      },
      {
        component: "OrderSheetList",
        path: "/order-sheet",
        componentPath: "pages/Orders/OrderSheetList",
        name: "OrderSheetList",
        auth: false,
        exact: true,
      },
      {
        component: "TrimOrdersList",
        path: "/trim-ordering",
        componentPath: "pages/Orders/TrimOrdersList",
        name: "TrimOrdersList",
        auth: false,
        exact: true,
      },
      {
        component: "TrimOrdering",
        path: "/trim-ordering/create-requirement",
        componentPath: "pages/Orders/TrimOrdering",
        name: "TrimOrdering",
        auth: false,
        exact: true,
      },
      {
        component: "TrimOrderingSelectOrders",
        path: "/trim-ordering/select-orders",
        componentPath: "pages/Orders/TrimOrderingSelectOrders",
        name: "TrimOrderingSelectOrders",
        auth: false,
        exact: true,
      },
      {
        component: "TrimOrderingTrimList",
        path: "/trim-ordering/trim-list",
        componentPath: "pages/Orders/TrimOrderingTrimList",
        name: "TrimOrderingTrimList",
        auth: false,
        exact: true,
      },
      {
        component: "TrimPurchaseForm",
        path: "/trim-ordering/trim-purchase",
        componentPath: "pages/Orders/TrimPurchaseForm",
        name: "TrimPurchaseForm",
        auth: false,
        exact: true,
      },
      {
        component: "TrimOrderingApprovalPage",
        path: "/trim-ordering-approval",
        componentPath: "pages/Orders/TrimOrderingApprovalPage",
        name: "TrimOrderingApprovalPage",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/costing",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "CostingSheet",
        path: "/costing-sheet",
        componentPath: "pages/CostingSheet",
        name: "CostingSheet",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/fabric-ordering",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "addNewFabricOrder",
        path: "/new-fabricOrder",
        componentPath: "pages/FabricOrdering/addNewFabricOrder.jsx",
        name: "CreateFabricOrder",
        auth: false,
        exact: true,
      },
      {
        component: "listFabricOrder",
        path: "/list-fabricOrder",
        componentPath: "pages/FabricOrdering/listFabricOrderPage",
        name: "listFabricOrderPage",
        auth: false,
        exact: true,
      },
      {
        component: "FabricListOrderWise",
        path: "/list-OrderFabric",
        componentPath: "pages/FabricOrdering/FabricListOrderWise",
        name: "FabricOrderListOrderPage",
        auth: false,
        exact: true,
      },
      {
        component: "fabricOrderApproval",
        path: "/list-fabricApproval",
        componentPath: "pages/FabricOrdering/fabricOrderApproval",
        name: "FabricOrderApprovalPage",
        auth: false,
        exact: true,
      },
      {
        component: "FabricOrdersList",
        path: "/",
        componentPath: "pages/FabricOrdering/FabricOrdersList",
        name: "FabricOrdersList",
        auth: false,
        exact: true,
      },
      {
        component: "selectAfterFabricOrderPage",
        path: "/selected-fabricList",
        componentPath: "pages/FabricOrdering/selectAfterFabricOrderPage",
        name: "SelectedFabricOrders",
        auth: false,
        exact: true,
      },
    ],
  },

  //Inward Goods
  {
    component: "MainLayout",
    path: "/inward-goods",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "InwardGoodsList",
        path: "/",
        componentPath: "pages/InwardGoods/InwardGoodsList",
        name: "InwardGoodsList",
        auth: false,
        exact: true,
      },
      {
        component: "CreateFabricGrn",
        path: "/fabric-grn-create",
        componentPath: "pages/InwardGoods/CreateFabricGrn",
        name: "CreateFabricGrn",
        auth: false,
        exact: true,
      },
      {
        component: "FabricDetailPage",
        path: "/fabric-detail",
        componentPath: "pages/InwardGoods/FabricDetailPage",
        name: "FabricDetailPage",
        auth: false,
        exact: true,
      },
      {
        component: "FabricCheckPage",
        path: "/fabric-check",
        componentPath: "pages/InwardGoods/FabricCheckPage",
        name: "FabricCheckPage",
        auth: false,
        exact: true,
      },

      // trim grn
      {
        component: "TrimCheckPage",
        path: "/trim-check",
        componentPath: "pages/InwardGoods/TrimCheckPage",
        name: "TrimCheckPage",
        auth: false,
        exact: true,
      },
      {
        component: "CreateTrimGrn",
        path: "/trim-grn-create",
        componentPath: "pages/InwardGoods/CreateTrimGrn",
        name: "CreateTrimGrn",
        auth: false,
        exact: true,
      },
      {
        component: "TrimDetailPage",
        path: "/trim-detail",
        componentPath: "pages/InwardGoods/TrimDetailPage",
        name: "TrimDetailPage",
        auth: false,
        exact: true,
      },
    ],
  },

  //Goods Issue
  {
    component: "MainLayout",
    path: "/goods-issue",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "index",
        path: "/",
        componentPath: "pages/GoodsIssue/index",
        name: "goodsIssueListPage",
        auth: false,
        exact: true,
      },
      {
        component: "ViewFabricDetails",
        path: "/view-fabric",
        componentPath: "pages/GoodsIssue/ViewFabricDetails",
        name: "ViewDetailsFabric",
        auth: false,
        exact: true,
      },
      {
        component: "ViewTrimDetails",
        path: "/view-trim",
        componentPath: "pages/GoodsIssue/ViewTrimDetails",
        name: "ViewDetailsTrim",
        auth: false,
        exact: true,
      },
      {
        component: "ViewFabricIssued",
        path: "/view-fabric-issue",
        componentPath: "pages/GoodsIssue/ViewFabricIssued",
        name: "ViewFabricIssuedPage",
        auth: false,
        exact: true,
      },
      {
        component: "ViewTrimIssued",
        path: "/view-trim-issue",
        componentPath: "pages/GoodsIssue/ViewTrimIssued",
        name: "ViewTrimIssuedPage",
        auth: false,
        exact: true,
      },
    ],
  },

  //Roles And Permissions
  {
    component: "MainLayout",
    path: "/add-user",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "index",
        path: "/",
        componentPath: "pages/RolesAndPage/index",
        name: "RoleAndStaffPage",
        auth: false,
        exact: true,
      },
      //Roles
      {
        component: "AddEditRoles",
        path: "/add-edit-role",
        componentPath: "pages/RolesAndPage/AddEditRoles",
        name: "AddEditRoles",
        auth: false,
        exact: true,
      },
      //Users
      {
        component: "AddEditUsers",
        path: "/add-edit-users",
        componentPath: "pages/RolesAndPage/AddEditUsers",
        name: "AddEditUsers",
        auth: false,
        exact: true,
      },
    ],
  },
  //Line Plan
  {
    component: "MainLayout",
    path: "/line-plan",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "LinePlanDashboard",
        path: "/",
        componentPath: "pages/LinePlan/LinePlanDashboardPage.jsx",
        name: "LinePlanDashboard",
        auth: false,
        exact: true,
      },
    ],
  },

  //Production Status
  {
    component: "MainLayout",
    path: "/production-status",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "index",
        path: "/",
        componentPath: "pages/ProductionStatusPage/index",
        name: "ProductionPage",
        auth: false,
        exact: true,
      },
      {
        component: "viewOrder",
        path: "/view-order",
        componentPath: "pages/ProductionStatusPage/viewOrder",
        name: "ViewOrderDetailsPage",
        auth: false,
        exact: true,
      },
      {
        component: "fabricOrderStatus",
        path: "/view-fabricOrder",
        componentPath: "pages/ProductionStatusPage/fabricOrderStatus",
        name: "FabricOrderStatusPage",
        auth: false,
        exact: true,
      },
      {
        component: "trimOrderStatus",
        path: "/view-trimOrder",
        componentPath: "pages/ProductionStatusPage/trimOrderStatus",
        name: "TrimOrderStatusPage",
        auth: false,
        exact: true,
      },
    ],
  },

  //Notification
  {
    component: "MainLayout",
    path: "/notification",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "index",
        path: "/",
        componentPath: "pages/Notification",
        name: "Notification",
        auth: false,
        exact: true,
      },
    ],
  },
];

export default routers;
