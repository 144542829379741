import { trimOrders } from "redux/actionType/actionType";

const initialState = {
  selectedOrdersListTrim: [],
  selectedTrimListInTrimOrdering: [],
  selectedTrimRequestDetail: {
    selectedTrimRequestId: null,
    type: "",
  },
};

export default (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case trimOrders.selectedOrdersListTrim:
      return {
        ...state,
        selectedOrdersListTrim: payload,
      };
    case trimOrders.selectedTrimListInTrimOrdering:
      return {
        ...state,
        selectedTrimListInTrimOrdering: payload,
      };
    case trimOrders.selectedTrimRequestId:
      console.log(payload, "pdata");
      return {
        ...state,
        selectedTrimRequestDetail: {
          selectedTrimRequestId: payload?.trimRequestId,
          type: payload?.type,
        },
      };
    default:
      return state;
  }
};
