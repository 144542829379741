import { Navbar } from "react-bootstrap";
import PropTypes from "prop-types";
import UserIcon from "assets/images/svg/user-icon.svg";
import { logout } from "service/utilities";
import Dropdown from "react-bootstrap/Dropdown";
import { history } from "helpers";
import { login } from "redux/actions/authActions";
import "./navbar.scss";
import { bindActionCreators } from "redux";
import { NotificationsCount } from "redux/actions/notificationActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { notification } from "redux/actionType/actionType";

/**
 * Navbar: The common re-usable navbar component
 * @return {JSX.Element} The JSX Code for the Home Page
 */

function AdminNavbar({ isOpen, notificationCountApi }) {
  const dispatch = useDispatch();
  const { count } = useSelector((state) => state?.notificationFunc);
  const userData = localStorage.getItem("userDetails");
  const data = userData ? JSON.parse(userData) : null;
  const username = data ? data.username : "Guest";
  console.log(username, "username");
  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    getNotificationCount();
    // getLogin();
  }, [count]);

  const getNotificationCount = () => {
    notificationCountApi().then(({ data }) => {
      // console.log(data, "datacheck");
      dispatch({
        type: notification.getNotificationCount,
        payload: data?.totalCount,
      });
    });
  };

  return (
    <div className="navbar p-0">
      <Navbar
        expand="lg"
        variant="light"
        bg="light"
        fixed="top"
        className={`admin-navbar ${isOpen ? "open-sidebar" : "closed-sidebar"}`}
      >
        <div className="d-flex align-items-center ms-auto navbar-user-info fs-14 cursor-pointer">
          <div className="notification_container" onClick={() => count !== 0 && history.push("/notification")}>
            <button
              type="button"
              className="btn p-0 no-style d-flex me-2"
            >
              <i className="icon-bell fs-22" />
            </button>

            {count !== 0 && (
              <div className="notification_count">
                <span>{count}</span>
              </div>
            )}
          </div>
          <Dropdown>
            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
              <img src={UserIcon} alt="User Name" /> {username}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={() => handleLogout()}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <button type="button" className="btn p-0 no-style d-flex mr-50">
            <i className="icon-setting fs-24" />
          </button> */}
        </div>
      </Navbar>
    </div>
  );
}

AdminNavbar.propTypes = {
  isOpen: PropTypes.bool,
};

AdminNavbar.defaultProps = {
  isOpen: true,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      notificationCountApi: NotificationsCount,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(AdminNavbar);
