export const trimOrders = {
  selectedOrdersListTrim: "SELECTED_ORDERS_LIST_TRIM",
  selectedTrimListInTrimOrdering: "SELECTED_TRIM_LIST_IN_TRIM_ORDERING",
  selectedTrimRequestId: "SELECTED_TRIM_REQUEST_ID",
};

export const fabricOrders = {
  selectedOrdersListFabric: "SELECTED_ORDERS_LIST_FABRIC",
  selectedFabricListInFabricOrdering: "SELECTED_FABRIC_LIST_IN_FABRIC_ORDERING",
  selectedFabricRequestId: "SELECTED_FABRIC_REQUEST_ID",
};

export const notification = {
  getNotificationCount: "GET_NOTIFICATION_COUNT",
};

export const linePlanCalendarData = {
  getLinePlanCalendarData: "GET_LINE_PLAN_CALENDAR_DATA",
};
