import React, { Component, Suspense } from "react";
import { Route, Router, Redirect } from "react-router-dom";
import CodeSplitter from "helpers/CodeSplitter";
import { ToastBar, Toaster } from "react-hot-toast";
import Routers from "./routers";
import * as Layout from "../layouts";
import { history } from "../helpers";
import { defaultValidator } from "helpers/utis";

class RoutesClass extends Component {
  render() {
    return (
      <Router history={history}>
        <Suspense fallback={<div>Loading...</div>}>
          {Routers.map(
            ({
              component,
              name,
              componentPath = "",
              redirect,
              path,
              exact = false,
              childrens = [],
            }) => {
              if (childrens.length > 0) {
                return (
                  <Route
                    path={path}
                    exact={exact}
                    key={path}
                    render={(props) => {
                      if (redirect) {
                        if (props.location.pathname === path) {
                          props.history.push(redirect);
                        }
                      }

                      const LayoutComponent = Layout[component];

                      return (
                        <LayoutComponent {...props}>
                          {childrens.map(
                            ({
                              componentPath: childComponentPath,
                              name: childrenName = "",
                              path: childrenPath,
                              exact: chidrenExact = false,
                            }) => {
                              CodeSplitter.addComponent(
                                childComponentPath,
                                childrenName
                              );

                              return (
                                <Route
                                  path={path + childrenPath}
                                  exact={chidrenExact}
                                  key={path + childrenPath}
                                  render={(childrenProps) => {
                                    const PageComponent =
                                      CodeSplitter.getComponent(childrenName);

                                    return <PageComponent {...childrenProps} />;
                                  }}
                                />
                              );
                            }
                          )}
                        </LayoutComponent>
                      );
                    }}
                  />
                );
              }

              CodeSplitter.addComponent(componentPath, name);

              return (
                <Route
                  path={path}
                  exact={exact}
                  key={defaultValidator(component, 2322)}
                  render={(props) => {
                    if (component) {
                      const PageComponent = CodeSplitter.getComponent(name);
                      return <PageComponent />;
                    }

                    if (redirect) {
                      if (props.location.pathname === path) {
                        return <Redirect to={redirect} />;
                      }
                    }

                    return <div />;
                  }}
                />
              );
            }
          )}
        </Suspense>
        <Toaster
          position="bottom-right"
          gutter={8}
          reverseOrder
          containerClassName="custom-toast"
          containerStyle={{ right: "40px", bottom: "50px" }}
        >
          {(t) => (
            <ToastBar toast={t} style={{ padding: 0, overflow: "hidden" }}>
              {({ icon, message }) => {
                return (
                  <div
                    className={`custom-toast-${t.type} d-flex align-items-center custom-toast-item`}
                  >
                    {icon}
                    {message}
                  </div>
                );
              }}
            </ToastBar>
          )}
        </Toaster>
      </Router>
    );
  }
}

export default RoutesClass;
