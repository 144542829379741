import { generateQuery } from "helpers/utis";

export const AuthApi = {
  loginApi: {
    api: "/user/login",
    baseURL: "user",
    method: "post",
  },
  forgotPasswordApi: {
    api: "/user/forgotpassword",
    baseURL: "user",
    method: "post",
  },
  resetPasswordApi: {
    api: "/user/setpassword",
    baseURL: "user",
    method: "post",
  },
};

export const OrderApi = {
  orderClosing: {
    url: "/order/closing",
    baseURL: "user",
    url_id: "",
    get api() {
      return this.url + "/" + this.url_id;
    },
    method: "put",
  },

  createOrderApi: {
    api: "/order/create",
    baseURL: "user",
    method: "post",
  },
  updateOrderApi: {
    api: "/order/update",
    baseURL: "user",
    method: "post",
  },
  getOrderApi: {
    url: "/order/view",
    baseURL: "user",
    method: "get",
    query: {
      url_id: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getSingleTrimOrder: {
    url: "/costingSheet/trimDetails/view",
    baseURL: "user",
    method: "get",
    query: {
      url_id: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getSingleTrimRequestApi: {
    url: "/trimOrder/request/view",
    baseURL: "user",
    method: "get",
    query: {
      url_id: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateSingleTrimRequestApi: {
    url: "/trimOrder/request/update",
    baseURL: "user",
    method: "put",
    query: {
      url_id: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  orderListApi: {
    url: "/order/list",
    baseURL: "user",
    query: {
      page: 1,
      size: 10,
      search: null,
      sortBy: null,
      sortColumn: null,
      filterStatus: null,
      type: null,
      filterForGoods: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  orderApprovalApi: {
    api: "/order/approve",
    baseURL: "user",
    method: "post",
  },
  trimOrderListApi: {
    url: "/trimOrder/request/list",
    baseURL: "user",
    query: {
      page: 1,
      size: 10,
      search: null,
      sortBy: null,
      sortColumn: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  trimOrderCreateApi: {
    api: "/trimORder/request/create",
    baseURL: "user",
    method: "post",
  },

  calculateTrimReqApi: {
    api: "/order/calculateTrimRequirement",
    baseURL: "user",
    method: "post",
  },

  calculateTrimToleranceApi: {
    api: "/order/calculateTrimTolerance",
    baseURL: "user",
    method: "post",
  },
  imageUploadApi: {
    api: "/upload",
    baseURL: "user",
    method: "post",
  },

  deleteOrderApi: {
    api: "/order/delete",
    baseURL: "user",
    method: "post",
  },
  updateTrimOrderStatusApi: {
    api: "/trimOrder/updateStatus",
    baseURL: "user",
    method: "post",
  },
  generatePOApi: {
    api: "/trimOrder/generatePO",
    baseURL: "user",
    method: "post",
  },
  trimOrderListPO: {
    url: "/trimOrder/listPO",
    baseURL: "user",
    query: {
      page: 1,
      size: 10,
      search: null,
      sortBy: null,
      sortColumn: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getOrderDetailApi: {
    url: "/trimOrder/trimRequestData?trimRequestIds=",
    baseURL: "user",
    method: "get",
    url_id: null,
    get api() {
      return this.url + this.url_id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getWarehouseApi: {
    url: "/getWarehouse",
    baseURL: "user",
    method: "get",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getSupplierApi: {
    url: "/getSupplier",
    baseURL: "user",
    method: "get",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getPaymentTermApi: {
    url: "/getPaymentTerm",
    baseURL: "user",
    method: "get",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getTrimConsumptionApi: {
    url: "/trimOrder/getTrimConsumption",
    baseURL: "user",
    query: {
      costingSheetId: null,
      trimDetailsId: null,
    },
    get api() {
      return (
        this.url +
        "/" +
        this.query.costingSheetId +
        "/" +
        this.query.trimDetailsId
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  updateOrderStatusApi: {
    url: "/trimOrder/request/status",
    baseURL: "user",
    method: "put",
    query: {
      url_id: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  deleteTrimOrderApi: {
    url: "/trimOrder/request/delete",
    baseURL: "user",
    method: "delete",
    query: {
      url_id: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  // download pdf

  generateTrimPdf: {
    url: "/trimOrder/genPDF",
    baseURL: "user",
    method: "get",
    query: {
      url_id: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getfabricApprovalListApi: {
    url: "/fabricOrder/listPO",
    baseURL: "user",
    query: {
      page: 1,
      size: 10,
      search: null,
      sortBy: null,
      sortColumn: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  fabricApprovalApi: {
    api: "/fabricOrder/updateStatus",
    baseURL: "user",
    method: "post",
  },

  fabricRequestCreate: {
    api: "/fabricOrder/request/create",
    baseURL: "user",
    method: "post",
  },

  getFabricConsumption: {
    url: "/fabricOrder/getFabricConsumption",
    baseURL: "user",
    query: {
      costingSheetId: null,
      url_id: null,
    },
    get api() {
      return (
        this.url + "/" + this.query.costingSheetId + "/" + this.query.url_id
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  calculateFabricRequire: {
    api: "/order/calculateFabricRequirement",
    baseURL: "user",
    method: "post",
  },
  calculateFabricTolerance: {
    api: "/order/calculateFabricTolerance",
    baseURL: "user",
    method: "post",
  },

  getFabricPlacedList: {
    url: "/fabricOrder/request/list",
    baseURL: "user",
    query: {
      page: 1,
      size: 10,
      search: null,
      sortBy: null,
      sortColumn: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  deleteFabricOrderApi: {
    url: "/fabricOrder/request/delete",
    baseURL: "user",
    method: "delete",
    query: {
      url_id: "",
    },
    get api() {
      return this.url + "/" + this.query.url_id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateFabricRequest: {
    url: "/fabricOrder/request/update",
    baseURL: "user",
    method: "put",
    query: {
      url_id: "",
    },
    get api() {
      return this.url + generateQuery(this?.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFabricListById: {
    url: "/fabricOrder/request/view",
    baseURL: "user",
    query: {
      fabricRequestId: null,
    },
    get api() {
      return this.url + "/" + this.query.fabricRequestId;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  updateFabricStatusAPI: {
    url: "/fabricOrder/request/status",
    baseURL: "user",
    query: {
      url_id: null,
    },
    get api() {
      return this.url + "/" + this.query.url_id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
    method: "put",
  },

  getToleranceValid: {
    api: "/order/fetchTolerance",
    baseURL: "user",
    method: "post",
  },

  generatePOForFabric: {
    api: "/fabricOrder/generatePO",
    baseURL: "user",
    method: "post",
  },

  generateFabricPOPDF: {
    url: "/fabricOrder/genPDF",
    baseURL: "user",
    query: {
      url_id: null,
    },
    get api() {
      return this.url + "/" + this.query.url_id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  //Fabric Selected ID

  fabricPurchasedIDS: {
    url: "/fabricOrder/fabricRequestData?fabricRequestIds=",
    baseURL: "user",
    method: "get",
    url_id: null,
    get api() {
      return this.url + this.url_id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  //Get currency code from common

  getCurrencyTypes: {
    api: "/getCurrencyCodes",
    baseURL: "user",
    method: "get",
  },
};

export const costingSheet = {
  costingSheetList: {
    url: "/costingSheet/list",
    baseURL: "user",
    query: {
      page: 1,
      size: 10,
      fileName: null,
      dateModified: null,
      startDate: null,
      endDate: null,
      unOrderedList: false,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getCostingSheetPrepopulatedData: {
    url: "/costingSheet/consolidatedView/",
    baseURL: "user",
    url_id: null,
    get api() {
      return this.url + this.url_id;
    },
  },

  gettrackCostingSheetView: {
    url: "/costingSheet/trackingSheetStatus/",
    baseURL: "user",
    url_id: null,
    get api() {
      return this.url + this.url_id;
    },
  },
  fileUploadApi: {
    api: "/costingSheet/upload",
    baseURL: "user",
    method: "post",
  },
  deleteCostingSheet: {
    api: "/costingSheet/delete",
    baseURL: "user",
    method: "post",
  },
  viewCostingSheet: {
    url: "/costingSheet/view/",
    baseURL: "user",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  getTrimOrderSublist: {
    url: "/costingSheet/trimDetails/",
    baseURL: "user",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  getFabricOrderSublist: {
    url: "/costingSheet/fabricDetails/",
    baseURL: "user",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
};

export const InwardGoods = {
  // fabric endpoints starts
  listFabricGrn: {
    url: "/grn/list",
    baseURL: "user",
    query: {
      page: 1,
      size: "",
      type: "fabric",
      search: "",
      fabricPOId: "",
      fabricDetailsId: "",
      color: "",
      trimPOId: "",
      trimDetailsId: "",
      mainList: 0,
      limit: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  listFabricPo: {
    url: "/fabricOrder/listPo",
    baseURL: "user",
    query: {
      page: 1,
      size: 10,
      search: "",
      filterStatus:1
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  viewFabricPO: {
    url: "/fabricOrder/viewPO",
    baseURL: "user",
    query: {
      url_id: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  viewFabricPOSubList: {
    url: "/fabricOrder/viewPO",
    baseURL: "user",
    query: {
      url_id: "",
      fabricDetailsId: "",
    },
    get api() {
      return (
        this.url +
        "/" +
        this.query.url_id +
        "?" +
        "fabricDetailsId" +
        "=" +
        this.query.fabricDetailsId
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  viewTrimPOSubList: {
    url: "/trimOrder/viewPO",
    baseURL: "user",
    query: {
      url_id: "",
      trimDetailsId: "",
    },
    get api() {
      return (
        this.url +
        "/" +
        this.query.url_id +
        "?" +
        "trimDetailsId" +
        "=" +
        this.query.trimDetailsId
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  grnChecking: {
    api: "/grn/grnChecking",
    baseURL: "user",
    method: "post",
  },
  fabricDetail: {
    url: "/grn/previousData",
    baseURL: "user",
    query: {
      url_id: "",
      type: "",
      detailsId: "",
      reqId: "",
      color: "",
    },
    get api() {
      return (
        this.url +
        "/" +
        this.query.url_id +
        "?" +
        "type" +
        "=" +
        this.query.type +
        "&" +
        "detailsId" +
        "=" +
        this.query.detailsId +
        "&" +
        "requestId" +
        "=" +
        this.query.reqId +
        "&" +
        "color" +
        "=" +
        this.query.color
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  viewFabricGrn: {
    url: "/grn/view",
    baseURL: "user",
    query: {
      url_id: "",
      type: "fabric",
    },
    get api() {
      return (
        this.url +
        "/" +
        this.query.url_id +
        "?" +
        "type" +
        "=" +
        this.query.type
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  // Trim endpoint starts
  listTrimPo: {
    url: "/trimOrder/listPo",
    baseURL: "user",
    query: {
      page: 1,
      size: 10,
      type: "trim",
      search: "",
      filterStatus:1
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  viewTrimPO: {
    url: "/trimOrder/viewPO",
    baseURL: "user",
    query: {
      url_id: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  addGrn: {
    api: "/grn/add",
    baseURL: "user",
    method: "post",
  },
};

//Goods Issue APIs

export const goodsIssue = {
  listOrderGoods: {
    url: "/grn/goodsIssue/orderBasedList",
    baseURL: "user",
    query: {
      page: 1,
      size: 10,
      type: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  listSubTables: {
    url: "/grn/goodsIssue/orderSubList",
    baseURL: "user",
    url_id: "",
    query: {
      type: "",
    },
    get api() {
      return this.url + "/" + this.url_id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  listSubSubTables: {
    url: "/grn/goodsIssue/orderSubSubList",
    baseURL: "user",
    url_id: "",
    query: {
      type: "",
      detailsId: "",
    },
    get api() {
      return this.url + "/" + this.url_id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  viewGoodsData: {
    url: "/grn/goodsIssue/view",
    baseURL: "user",
    url_id: "",
    query: {
      type: "",
      color: "",
    },
    get api() {
      return this.url + "/" + this.url_id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  requestGoodsIssue: {
    api: "/grn/goodsIssue/add",
    baseURL: "user",
    method: "post",
  },

  issueGoods: {
    url: "/grn/goodsIssue/issue",
    baseURL: "user",
    method: "put",
    url_id: "",
    get api() {
      return this.url + "/" + this.url_id;
    },
  },
};

export const roleDetails = {
  listRoles: {
    url: "/role/list",
    baseURL: "user",
    query: {
      page: null,
      size: null,
      search: null,
      sortBy: null,
      sortColumn: null,
      filterStatus: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  listUsers: {
    url: "/user/list",
    baseURL: "user",
    query: {
      page: null,
      size: null,
      search: null,
      sortBy: null,
      sortColumn: null,
      filterStatus: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getActionsList: {
    api: "/role/getActionList",
    baseURL: "user",
    method: "get",
  },

  createRole: {
    api: "/role/create",
    baseURL: "user",
    method: "post",
  },

  editRole: {
    url: "/role/update",
    baseURL: "user",
    url_id: null,
    method: "put",
    get api() {
      return this.url + "/" + this.url_id;
    },
  },
  deleteRoles: {
    api: "/role/delete",
    baseURL: "user",
    method: "post",
  },

  viewRoleDetails: {
    url: "/role/view",
    baseURL: "user",
    roleId: null,
    get api() {
      return this.url + "/" + this.roleId;
    },
  },

  addUserData: {
    api: "/user/register",
    baseURL: "user",
    method: "post",
  },

  viewUserData: {
    url: "/user/view",
    baseURL: "user",
    url_id: null,
    get api() {
      return this.url + "/" + this.url_id;
    },
  },

  updateUserData: {
    api: "/user/update",
    baseURL: "user",
    method: "post",
  },
  deleteUserData: {
    api: "/user/delete",
    baseURL: "user",
    method: "post",
  },
};

export const linePlan = {
  getlineData: {
    url: "/linePlan/getProductionProgress",
    baseURL: "user",
    query: {
      type: null,
      currentDate: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAlertsData: {
    url: "/linePlan/getLinePlan",
    baseURL: "user",
    query: {
      type: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  postAlertEmailData: {
    api: "/linePlan/alertEmail",
    baseURL: "user",
    method: "post",
  },
  getUnAllocatedOrders: {
    api: "/linePlan/unallocatedOrder",
    baseURL: "user",
    method: "get",
  },
  getUnitList: {
    api: "/linePlan/unit/list",
    baseURL: "user",
    method: "get",
  },
  getLinesList: {
    url: "/linePlan/lines",
    baseURL: "user",
    method: "get",
    query: {
      url_id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getLinePlanCalendar: {
    url: "/linePlan/getLinePlanForCalendar",
    baseURL: "user",
    query: {
      startDate: null,
      endDate: null,
      unitId: null,
      lineId: null,
    },
    get api() {
      return (
        this.url +
        "?" +
        "startDate" +
        "=" +
        this.query.startDate +
        "&" +
        "endDate" +
        "=" +
        this.query.endDate +
        "&" +
        "unitId" +
        "=" +
        this.query.unitId +
        "&" +
        "lineId" +
        "=" +
        this.query.lineId
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addLinePlan: {
    api: "/linePlan/add",
    baseURL: "user",
    method: "post",
  },
  getLinePlanById: {
    url: "/linePlan/getLinePlanById",
    baseURL: "user",
    method: "get",
    query: {
      url_id: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateActualValue: {
    api: "/linePlan/updateActual",
    baseURL: "user",
    method: "post",
  },
  storeSampleTNA: {
    url: "/linePlan/storeSampleTNA",
    baseURL: "user",
    method: "put",
    query: {
      url_id: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const productStatus = {
  viewOrderDetails: {
    url: "/productionStatus/viewTableForm",
    baseURL: "user",
    url_id: null,
    get api() {
      return this.url + "/" + this.url_id;
    },
  },

  viewGoodsStatus: {
    url: "/productionStatus/viewCostingSheetItemStatus",
    baseURL: "user",
    url_id: null,
    query: {
      type: null,
    },
    get api() {
      return this.url + "/" + this.url_id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

// notification
export const notification = {
  notificationCount: {
    api: "/notifications/count",
    baseURL: "user",
    method: "get",
  },
  notificationList: {
    url: "/notifications/list",
    baseURL: "user",
    method: "get",
    query: {
      page: "",
      limit: "",
      status: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  readNotification: {
    url: "/notifications/read",
    baseURL: "user",
    notificationId: "",
    get api() {
      return this.url + "/" + this.notificationId;
    },
    method: "put",
  },
};
