import { addQuery } from "helpers/utis";
import { notification } from "service/apiVariables";

export const NotificationsCount =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...notification.notificationCount })
        .then((data) => resolve(data))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

export const notificationListAct =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, notification.notificationList);
    return new Promise((resolve, reject) => {
      api({ ...notification.notificationList })
        .then((data) => resolve(data))
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };

export const readNotificationAct =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    notification.readNotification.notificationId = id;
    return new Promise((resolve, reject) => {
      api({ ...notification.readNotification })
        .then((data) => {
          Toast({ type: "success", message: data.message });
          resolve(data);
        })
        .catch(({ message }) => reject(Toast({ type: "error", message })));
    });
  };
