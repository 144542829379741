import { combineReducers } from "redux";
import trimOrders from "./trimOrders";
import fabricOrders from "./fabricOrder";
import notificationFunc from "./notification";
import getLinePlanForCalendarFunc from "./linePlan";

export const reducers = combineReducers({
  trimOrders,
  fabricOrders,
  notificationFunc,
  getLinePlanForCalendarFunc
});
