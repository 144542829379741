import axios from "axios";

import config from "../config";
import { Toast } from "./toast";
import moment from "moment";

export const axiosInstance = axios.create({
  headers: {},
});

export const cancelTokenSource = axios.CancelToken.source();

export const setLocalStorageData = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageData = (key) => {
  if (!key) return null;
  return JSON.parse(localStorage.getItem(key));
};

export const logout = () => {
  axiosInstance.defaults.headers.common.Authorization = "";
  localStorage.removeItem("userDetails");
  localStorage.removeItem("token");
  setTimeout(() => {
    window.location.pathname = "/";
  }, 200);
};

export const getServiceUrl = (baseURL) => {
  let finalURL = "";

  switch (baseURL) {
    case "user":
      finalURL = config.api.userService;
      break;
    case "normal":
      finalURL = config.api.normalService;
      break;
    case "payment":
      finalURL = config.api.paymentService;
      break;
    case "cms":
      finalURL = config.api.cmsService;
      break;
    case "booking":
      finalURL = config.api.bookingService;
      break;
    default:
      finalURL = config.api.normalService;
  }

  return finalURL;
};

export const statusHelper = (status, data) => {
  if (data.status === 401 && cancelTokenSource) cancelTokenSource.cancel();

  if (data.status === 401 || data.status === 403) {
    Toast({ type: "error", message: data.statusText });
    setTimeout(() => logout(), 1000);
  }
  if (status) {
    return { status: data.status, ...data.data };
  }
  return data.data;
};

export const isAuthorized = async () => {
  if (
    localStorage.getItem("token") !== null &&
    localStorage.getItem("token") !== undefined
  ) {
    return true;
  }
  return false;
};

export const sortBy = (type, field, reverse, primer) => {
  const key = primer
    ? function (x) {
        return primer(x[field]);
      }
    : function (x) {
        return x[field];
      };

  if (type === "descending") {
    reverse = reverse ? 1 : -1;
  } else {
    reverse = !reverse ? 1 : -1;
  }
  return (a, b) => {
    return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
  };
};

export const parseStringtoJSON = (data) => {
  try {
    const parsedData = JSON.parse(data);
    return parsedData || [];
  } catch (error) {
    console.error("Error parsing waistSize data:", error);
    return [];
  }
};

export const momentConvertor = (data, format) => {
  let modifiedData = data.map((d) => ({
    ...d,
    date: moment(d.date).format(format),
  }));
  return modifiedData;
};
