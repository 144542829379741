import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { api } from 'service/api';
import { Toast } from 'service/toast';

import { reducers } from '../redux/reducer';

const history = require('history').createBrowserHistory({});

export const store = createStore(
  reducers,
  applyMiddleware(thunk.withExtraArgument({ api, Toast }))
);

export { history };
