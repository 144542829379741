import { fabricOrders } from "../actionType/actionType";

const initialState = {
  selectedOrdersListFabric: [],
  selectedFabricListInFabricOrdering: [],
  selectedFabricRequestId: {
    selectedFabricRequestId: null,
    type: "",
  },
};

const stateFunction = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case fabricOrders.selectedOrdersListFabric:
      return {
        ...state,
        selectedOrdersListFabric: payload,
      };
    case fabricOrders.selectedFabricListInFabricOrdering:
      return {
        ...state,
        selectedFabricListInFabricOrdering: payload,
      };
    case fabricOrders.selectedFabricRequestId:
      return {
        ...state,
        selectedFabricRequestId: payload,
      };

    default: {
      return state;
    }
  }
};

export default stateFunction;
